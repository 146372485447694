import React, { useState, useEffect, useRef } from "react";
import { QRCodeSVG } from "qrcode.react";
import TypedComponent from "./TypedComponent";
import "./ChatBox.css";

const API_URL = "";

const allVideosData = {
  idle: "/videos/idle.mp4",
  start: {
    englishurl: "/videos/HL_EN_INTRO.mp4",
    teluguurl: "/videos/HL_TE_INTRO.mp4",
    englishtext:
      "I am your Chief minister revanth reddy. Welcome to the Telangana Hospital, hope your health is well",
    telugutext:
      "నేను మీ ముఖ్యమంత్రి రేవంత్ రెడ్డిని. తెలంగాణా ఆసుపత్రికి స్వాగతం, మీ ఆరోగ్యం బాగుందని ఆశిస్తున్నాను.",
  },
  question: {
    englishurl: "/videos/HL_EN_Q1.mp4",
    teluguurl: "/videos/HL_TE_Q1.mp4",
    englishtext:
      "To serve you better, please provide your name, age, gender and purpose of visit",
    telugutext:
      "మీకు మెరుగైన సేవలందించేందుకు, దయచేసి మీ పేరు, వయస్సు, లింగం మరియు సందర్శన ఉద్దేశాన్ని అందించండి",
  },
  end: {
    englishurl: "/videos/HL_EN_END.mp4",
    teluguurl: "/videos/HL_TE_END.mp4",
    englishtext:
      "Thank you for providing your information. Take care and stay healthy. ",
    telugutext:
      "మీ సమాచారాన్ని అందించినందుకు ధన్యవాదాలు. జాగ్రత్తగా ఉండండి మరియు ఆరోగ్యంగా ఉండండి",
  },
  response1: {
    englishurl: "/videos/HL_EN_R1.mp4",
    teluguurl: "/videos/HL_TE_R1.mp4",
    englishtext:
      "for a general consultation, please visit the first floor, Outpatient Department. You can book an appointment at the reception desk or walk in during working hours.",
    telugutext:
      "సాధారణ సంప్రదింపుల కోసం, దయచేసి మొదటి అంతస్తులో ఉన్న అవుట్ పేషెంట్ డిపార్ట్‌మెంట్ను సందర్శించండి. మీరు రిసెప్షన్ డెస్క్ వద్ద అపాయింట్‌మెంట్ బుక్ చేసుకోవచ్చు లేదా పని వేళల్లో నేరుగా వచ్చి సలహా పొందవచ్చు.",
  },
  response2: {
    englishurl: "/videos/HL_EN_R2.mp4",
    teluguurl: "/videos/HL_TE_R2.mp4",
    englishtext:
      "for emergency services, please proceed directly to the Emergency Department on the ground floor. The department operates 24/7 and is equipped to handle urgent cases.",
    telugutext:
      "అత్యవసర సేవల కోసం, దయచేసి నేరుగా గ్రౌండ్ ఫ్లోర్‌లోని అత్యవసర విభాగానికి వెళ్లండి. ఈ విభాగం 24/7 పని చేస్తుంది మరియు అత్యవసర కేసులను చూడటానికి సన్నద్ధంగా ఉంటుంది.",
  },
  response3: {
    englishurl: "/videos/HL_EN_R3.mp4",
    teluguurl: "/videos/HL_TE_R3.mp4",
    englishtext:
      "to collect your medical report, visit the Medical Records Department located on the second floor. Please bring a valid ID and your hospital registration number.",
    telugutext:
      "మీ వైద్య నివేదికను పొందడానికి, రెండవ అంతస్తులో ఉన్న మెడికల్ రికార్డ్స్ విభాగానికి వెళ్లండి. దయచేసి మీ చెల్లుబాటు అయ్యే గుర్తింపు పత్రం మరియు ఆసుపత్రి నమోదు నంబర్ తీసుకురండి.",
  },
};

const VideoPlayer = () => {
  const [stage, setStage] = useState("idle-start");
  const [language, setLanguage] = useState("telugu");
  const [userInput, setUserInput] = useState("");
  const [responseType, setResponseType] = useState("response1");
  const [showStartButton, setShowStartButton] = useState(true);
  const [messages, setMessages] = useState([]);
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [showQRCode, setShowQRCode] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [micPermission, setMicPermission] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const chatBoxRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const videoRefs = {
    idle: useRef(null),
    start: useRef(null),
    question: useRef(null),
    response1: useRef(null),
    response2: useRef(null),
    response3: useRef(null),
    end: useRef(null),
  };

  useEffect(() => {
    checkAndRequestMicrophonePermission();
    Object.entries(videoRefs).forEach(([key, ref]) => {
      if (ref.current) {
        ref.current.src = getVideoUrl(key);
        ref.current.load();
      }
    });

    if (videoRefs.idle.current) {
      videoRefs.idle.current.muted = true;
      videoRefs.idle.current
        .play()
        .catch((error) => console.error("Autoplay failed:", error));
    }
  }, [language]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const checkAndRequestMicrophonePermission = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'microphone' });
      if (result.state === 'granted') {
        setMicPermission(true);
      } else if (result.state === 'prompt') {
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setMicPermission(true);
        } catch (err) {
          console.error("Error requesting microphone permission:", err);
          setMicPermission(false);
        }
      } else {
        console.warn("Microphone permission has been denied");
        setMicPermission(false);
      }
    } catch (err) {
      console.error("Error checking microphone permission:", err);
    }
  };

  const getVideoUrl = (video) => {
    if (video === "idle") return allVideosData.idle;
    return allVideosData[video][`${language}url`];
  };

  const playVideo = (videoKey) => {
    Object.entries(videoRefs).forEach(([key, ref]) => {
      if (key === videoKey) {
        ref.current.muted = false;
        ref.current
          .play()
          .catch((error) => console.error("Play failed:", error));
      } else {
        ref.current.pause();
        ref.current.currentTime = 0;
      }
    });
  };

  const addMessageToChat = (content, type = "bot") => {
    setMessages((prevMessages) => [...prevMessages, { type, content }]);
  };

  const handleVideoEnd = () => {
    switch (stage) {
      case "start":
        setStage("question");
        playVideo("question");
        addMessageToChat(allVideosData.question[`${language}text`]);
        break;
      case "question":
        setStage("idle_input");
        setInputDisabled(false);
        playVideo("idle");
        break;
      case "response":
        setStage("end");
        playVideo("end");
        addMessageToChat(allVideosData.end[`${language}text`]);
        break;
      case "end":
        setStage("idle_end");
        playVideo("idle");
        setShowQRCode(true);
        setQrCodeValue(generateQRCodeValue());
        break;
      default:
        break;
    }
  };

  const handleStartClick = () => {
    setStage("start");
    setShowStartButton(false);
    playVideo("start");
    addMessageToChat(allVideosData.start[`${language}text`]);
  };

  const handleNewChatClick = () => {
    setStage("question");
    setShowQRCode(false);
    setMessages([]);
    playVideo("question");
    addMessageToChat(allVideosData.question[`${language}text`]);
  };

  const startRecording = async () => {
    if (!micPermission) {
      alert("Microphone permission is required to use this feature.");
      await checkAndRequestMicrophonePermission();
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const chunks = [];
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        await handleAudioInput(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      addMessageToChat("Recording started...", "system");
      console.log("API_URL",API_URL);
      console.log("Recording started");
    } catch (error) {
      if (error.name === "NotAllowedError") {
        console.error("Permissions denied for audio recording.");
        setMicPermission(false);
      } else if (error.name === "NotFoundError") {
        console.error("No audio input devices found.");
      } else {
        console.error("Error starting recording:", error);
      }
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      addMessageToChat("Recording stopped. Processing...", "system");
      console.log("Recording stopped");
    } else {
      console.warn("No active recording found to stop.");
    }
  };

  const handleAudioInput = async (audioBlob) => {
    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.wav");

    setIsProcessing(true);
    try {
      // Step 2: Convert voice to text
      const voiceToTextResponse = await fetch(
        `${API_URL}/api/v1/voice-to-text`,
        {
          method: "POST",
          body: formData,
        }
      );
      const voiceToTextData = await voiceToTextResponse.json();
      const transcription = voiceToTextData.transcription;

      // Step 3: Detect language
      const detectLanguageResponse = await fetch(
        `${API_URL}/api/v1/detect-language`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ queryString: transcription }),
        }
      );
      const detectLanguageData = await detectLanguageResponse.json();
      const detectedLanguage = detectLanguageData.language;

      let finalText = transcription;
      console.log("finalText", finalText);
      setUserInput(finalText);
      addMessageToChat(finalText, "user");
      setInputDisabled(true);
      setUserInput("");

      // Step 5: Translate if necessary
      if (detectedLanguage === "te-Latn") {
        const translateResponse = await fetch(
          `${API_URL}/api/v1/translate-text`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              text: transcription,
              source_language: "te",
              target_language: "en",
            }),
          }
        );
        const translateData = await translateResponse.json();
        finalText = translateData.translated_text;
      }

      // Step 7: Send query to get response
      await handleQuery(finalText);
    } catch (error) {
      console.error("Error processing audio input:", error);
      addMessageToChat("Error processing audio. Please try again.", "system");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleQuery = async (query) => {
    setIsProcessing(true);
    try {
      const response = await fetch(`${API_URL}/api/query/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ queryString: query }),
      });
      const data = await response.json();
      setResponseType(data.response.response);
      setStage("response");
      playVideo(data.response.response);
      if (data.name === "Unknown") {
        addMessageToChat(
          allVideosData[data.response.response][`${language}text`]
        );
      } else {
        addMessageToChat(
          data.name +
            " " +
            allVideosData[data.response.response][`${language}text`]
        );
      }
    } catch (error) {
      console.error("Error submitting query:", error);
      addMessageToChat("Error processing your request. Please try again.", "system");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    addMessageToChat(userInput, "user");
    setInputDisabled(true);
    setIsProcessing(true);
    
    try {
      const detectLanguageResponse = await fetch(
        `${API_URL}/api/v1/detect-language`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ queryString: userInput }),
        }
      );
      const detectLanguageData = await detectLanguageResponse.json();
      const detectedLanguage = detectLanguageData.language;
      let finalText = userInput;
      if (detectedLanguage === "te-Latn") {
        const translateResponse = await fetch(
          `${API_URL}/api/v1/translate-text`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              text: finalText,
              source_language: "te",
              target_language: "en",
            }),
          }
        );
        const translateData = await translateResponse.json();
        finalText = translateData.translated_text;
      }
      await handleQuery(finalText);
    } catch (error) {
      console.error("Error processing input:", error);
      addMessageToChat("Error processing your request. Please try again.", "system");
    } finally {
      setIsProcessing(false);
      setUserInput("");
    }
  };

  const generateQRCodeValue = () => {
    return "UNIQUE_TOKEN_" + Math.random().toString(36).substr(2, 9);
  };

  const isVideoVisible = (key) => {
    if (key === "idle") {
      return (
        stage === "idle-start" || stage === "idle_input" || stage === "idle_end"
      );
    }
    if (key === "response1" || key === "response2" || key === "response3") {
      return stage === "response" && key === responseType;
    }
    return stage === key;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`container-fluid d-flex flex-column ${windowWidth < 1200 ? "min-vh-100" : "vh-100"}`}
    >
      <div className="row align-items-center mb-5">
        <div className="col-5">
          <img
            src="/pictures/logo.png"
            alt="Logo"
            className="img-fluid"
            style={{ maxWidth: "120px" }}
          />
        </div>
        <div className="col-7">
          <h3 className="mb-0">Welcome to TIMS</h3>
        </div>
      </div>

      <div className="row flex-grow-1 ">
        {windowWidth >= 1200 && (
          <div
            className="col-3 p-2 position-relative"
            style={{ height: "80%" }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "20%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "var(--bs-light)",
                padding: "100px 150px",
                borderRadius: "10px",
                color: "#2596be",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                fontSize: "2rem",
                fontWeight: "bold",
                textAlign: "center",
                maxWidth: "100%",
              }}
            >
              <TypedComponent />
            </div>
          </div>
        )}

        <div className="col-12 col-md-6 col-xl-4 p-2" style={{ height: "80%" }}>
          {Object.entries(videoRefs).map(([key, ref]) => (
            <video
              key={key}
              ref={ref}
              onEnded={handleVideoEnd}
              className={`w-100 h-100 object-fit-fill ${isVideoVisible(key) ? "d-block" : "d-none"}`}
              loop={key === "idle"}
              playsInline
            />
          ))}
        </div>
        <div
          className="col-12 col-md-6 col-xl-4 p-2 d-flex flex-column "
          style={{ height: "80%" }}
        >
          <div className="chat-header d-flex justify-content-between align-items-center  bg-light">
            <h5>Chat</h5>
            <select
              className="form-select"
              style={{ width: "auto" }}
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="telugu">Telugu</option>
              <option value="english">English</option>
            </select>
          </div>
          <div
            className="chat-messages flex-grow-1 overflow-auto"
            ref={chatBoxRef}
          >
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.type}`}>
                <strong>
                  {message.type === "user" ? "User: " : message.type === "system" ? "System: " : "AI Assistant: "}
                </strong>
                {message.content}
              </div>
            ))}
            {isProcessing && (
              <div className="text-center mt-2">
                <img src="/processing.gif" alt="Processing" style={{ width: "50px", height: "50px" }} />
                <p>Processing your request...</p>
              </div>
            )}
          </div>
          {!micPermission && (
            <div className="alert alert-warning" role="alert">
              Microphone access is required for voice input. Please enable it in your browser settings.
            </div>
          )}
          {showStartButton && (
            <div className="text-center ">
              <button className="btn btn-primary" onClick={handleStartClick}>
                Start
              </button>
            </div>
          )}
          {showQRCode && (
            <div className="qr-code-container text-center ">
              <h3>Your Token QR Code:</h3>
              <QRCodeSVG value={qrCodeValue} />
              <p>Token: {qrCodeValue}</p>
              <button
                className="btn btn-primary start-new-chat-button"
                onClick={handleNewChatClick}
              >
                Start New Chat
              </button>
            </div>
          )}
          {!showQRCode && !showStartButton && (
            <form onSubmit={handleSubmit} className="chat-input d-flex ">
              <input
                type="text"
                className="form-control me-2"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type your message..."
                disabled={inputDisabled || isProcessing}
              />
              <button
                type="button"
                className={`btn btn-outline-secondary me-2 ${isRecording ? "btn-danger" : ""}`}
                onMouseDown={startRecording}
                onMouseUp={stopRecording}
                onMouseLeave={stopRecording}
                disabled={inputDisabled || isProcessing}
              >
                <i
                  className={`bi bi-mic-fill ${isRecording ? "blink" : ""}`}
                ></i>
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={inputDisabled || isProcessing}
              >
                Send
              </button>
            </form>
          )}
        </div>
      </div>

      <div className="mt-auto">
        <div className="row py-3">
          <div className="col">
            <footer className="text-center">
              &copy; 2024 involgix.ai. All rights reserved.
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;