import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

const TypedComponent = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        "Exceptional Care for a Healthier Tomorrow",
        "ఉత్తమ వైద్యం, ఆరోగ్యమైన రేపు కోసం",
        "Your Health, Our Priority, Trusted Medical Services 24/7",
        "మీ ఆరోగ్యం, మా ప్రాధాన్యత, నమ్మదగిన వైద్య సేవలు 24/7",
        "Innovative Treatments, Compassionate Care, Welcome to TIMS",
        "నవీన చికిత్సలు, దయామయమైన సంరక్షణ, టిమ్స్ కు స్వాగతం",
        "Dedicated to Your Health, Quality Healthcare with a Personal Touch",
        "మీ ఆరోగ్యానికి అంకితమైన, వ్యక్తిగత తాకడితో నాణ్యమైన వైద్య సేవలు"
      ],
      typeSpeed: 80,
      backSpeed: 30,
      backDelay: 1000,
      startDelay: 1000,
      loop: true,
    };

    const typed = new Typed(typedRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  return <span ref={typedRef} />;
};

export default TypedComponent;
