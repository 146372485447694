import React from 'react';
import ChatBox from './components/ChatBox';
import './App.css'; // Ensure to import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const App = () => {


  return (
    <div className="container">

      <ChatBox />
    </div>
  );
};

export default App;
